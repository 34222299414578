import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx/';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {SDHttpInterceptorService} from './gateways/services/SDHttpInterceptorServices/sdhttp-interceptor.service';
import {Network} from '@ionic-native/network/ngx';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PipeModule} from "./pipes/pipe.module";
import {CommonModule, LocationStrategy, HashLocationStrategy} from "@angular/common";
import {PopoverListTubeComponent} from "./components/popover-liste-tube/popover-list-tube/popover-list-tube.component";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// ANGULAR MATERIAL
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
	declarations: [AppComponent,PopoverListTubeComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatInputModule,
		PipeModule,
		MatTooltipModule,
		MatIconModule,
		CommonModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
	],
	providers: [
		StatusBar,
		SplashScreen,
		// {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
		{provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: SDHttpInterceptorService, multi: true},
		{provide: MAT_DATE_LOCALE, useValue: 'fr'}, // Locale en français
        HttpClientModule,
		BarcodeScanner,
        Network,
		MatDatepickerModule,
		MatTooltipModule
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
