import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'select-site',
    loadChildren: () => import('./pages/select-site/select-site.module').then( m => m.SelectSitePageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'stock-modal',
    loadChildren: () => import('./pages/stock-modal/stock-modal.module').then(m => m.StockModalPageModule)
  },
  {
    path: 'modal-result-search',
    loadChildren: () => import('./pages/modal-result-search/modal-result-search.module').then( m => m.ModalResultSearchPageModule)
  },
  {
    path: 'colisage',
    loadChildren: () => import('./pages/colisage/colisage.module').then( m => m.ColisagePageModule)
  },
  {
    path: 'colisage-specifique',
    loadChildren: () => import('./pages/colisage-specifique/colisage-specifique.module').then( m => m.ColisageSpecifiquePageModule)
  },
  {
    path: 'reappro',
    loadChildren: () => import('./pages/reappro/reappro.module').then( m => m.ReapproPageModule)
  },
  {
    path: 'reception',
    loadChildren: () => import('./pages/reception/reception.module').then( m => m.ReceptionPageModule)
  },
  {
    path: 'modal-message-tube',
    loadChildren: () => import('./pages/modal-message-tube/modal-message-tube.module').then( m => m.ModalMessageTubePageModule)
  },
  {
    path: 'modal-login-intermediaire',
    loadChildren: () => import('./pages/modal-login-intermediaire/modal-login-intermediaire.module').then(m => m.ModalLoginIntermediairePageModule)
  },
  {
    path: 'modal-validation-reception-tube-urgent',
    loadChildren: () => import('./pages/modal-validation-reception-tube-urgent/modal-validation-reception-tube-urgent.module').then( m => m.ModalValidationReceptionTubeUrgentPageModule)
  },
  {
    path: 'modal-choose-deliveries',
    loadChildren: () => import('./pages/modal-choose-deliveries/modal-choose-deliveries.module').then( m => m.ModalChooseDeliveriesPageModule)
  },
  {
    path: 'modal-user-contact',
    loadChildren: () => import('./pages/modal-user-contact/modal-user-contact.module').then( m => m.ModalUserContactPageModule)
  },
  {
    path: 'show-deliveries-pp',
    loadChildren: () => import('./pages/show-deliveries-pp/show-deliveries-pp.module').then( m => m.ShowDeliveriesPPPageModule)
  },
  {
    path: 'modal-rempotage',
    loadChildren: () => import('./pages/modal-rempotage/modal-rempotage.module').then( m => m.ModalRempotagePageModule)
  },
  { path: 'internal-transport',
    loadChildren: () => import('./pages/internal-transport/internal-transport.module').then( m => m.InternalTransportPageModule)
  },
  {
    path: 'modal-reconstruct-container',
    loadChildren: () => import('./pages/modal-reconstruct-container/modal-reconstruct-container.module').then( m => m.ModalReconstructContainerPageModule)
  },
  {
    path: 'modal-search-partner',
    loadChildren: () => import('./pages/modal-search-partner/modal-search-partner.module').then( m => m.ModalSearchPartnerPageModule)
  },
  {
    path: 'site-selector-custom',
    loadChildren: () => import('./pages/site-selector-custom/site-selector-custom.module').then(m => m.SiteSelectorCustomPageModule)
  },
  {
    path: 'dashboard2024',
    loadChildren: () => import('./pages/dashboard2024/dashboard2024.module').then( m => m.Dashboard2024PageModule)
  },
  {
    path: 'modal-day-checkpoints',
    loadChildren: () => import('./pages/modal-day-checkpoints/modal-day-checkpoints.module').then( m => m.ModalDayCheckpointsPageModule)
  },
  {
    path: 'product-view',
    loadChildren: () => import('./pages/product-view/product-view.module').then( m => m.ProductViewPageModule)
  },
  {
    path: 'colisage2',
    loadChildren: () => import('./pages/colisage2/colisage2.module').then( m => m.Colisage2PageModule)
  },
  {
    path: 'reception2',
    loadChildren: () => import('./pages/reception2/reception2.module').then( m => m.Reception2PageModule)
  },
  {
    path: 'recherche',
    loadChildren: () => import('./pages/recherche/recherche.module').then( m => m.RecherchePageModule)
  },
  {
    path: 'laboratory-selector',
    loadChildren: () => import('./pages/laboratory-selector/laboratory-selector.module').then( m => m.LaboratorySelectorPageModule)
  },
  {
    path: 'modal-delivery-details',
    loadChildren: () => import('./pages/modal-delivery-details/modal-delivery-details.module').then( m => m.ModalDeliveryDetailsPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
